<template>
  <div>
    <!-- 1 -->
    <section class="main patterned-background">
      <div class="d-flex flex-column flex-md-row mb-5">
        <!-- Text Column: On mobile, order 2; on md and above, order 1 -->
        <div class="col-12 col-md-5 d-flex flex-column justify-content-center align-items-start p-3 order-2 order-md-1">
          <h1 class="f-secondary t-secondary text-gradient p-2">
            Where will your health career take you?
          </h1>
          <div class="f-primary p-2 line-height-30 mb-3">
            Whether you’re a student, carer or practicing professional; someone re-entering the health world or getting ready for retirement; we’re here to support you at every stage of your career.
          </div>
          <div class="d-flex justify-content-start p-2">
            <button class="btn btn-primary" @click="goToRegister()">Register Now</button>
          </div>
        </div>

        <!-- Image Column: On mobile, order 1; on md and above, order 2 -->
        <div class="col-12 col-md-7 p-0 order-1 order-md-2">
          <img
            src="../../../public/img/stm/carrer-pathways-hero.png"
            class="img-fluid position-relative z-1"
            alt="Career Pathways Hero"
          />
        </div>
      </div>
    </section>


    <!-- 2 -->
    <section class="main container-fluid">
      <div
        class="d-flex justify-content-center align-items-center flex-column mb-3"
      ></div>
      <!-- Starts -->
      <div class="row my-5">
        <!-- Left -->
        <div class="col-md-6 text-start order-md-1">
          <div>
            <h5 class="text-second text-uppercase mb-3">Our expertise</h5>
            <h2 class="f-secondary text-gradient-2 font-40 mb-3">
              100% accurate career-matching
            </h2>
            <p class="w-80">
              With an unmatched knowledge of the world’s health training
              pathways, associated qualifications, licensure, and visa
              requirements, we perform AI-based job-matching and upskilling with
              100% accuracy. We’re all about removing the doubt from your next
              career move, so you’re equipped with the knowledge and skills to
              thrive.
            </p>
          </div>
        </div>

        <!-- Right -->
        <div class="col-md-6 text-center order-md-2 mb-5 mb-md-0">
          <img
            class="img-fluid border-radius-40"
            src="../../../public/img/stm/work4.png"
            alt="Image Description"
          />
        </div>
      </div>
      <!-- Ends -->
    </section>
    <!-- 3 -->
    <FutureSection />
    <NewFooter />
    <ScrollToTop />
  </div>
</template>

<script>
import FutureSection from "./FutureSection.vue";
import NewFooter from "./NewFooter.vue";
import ScrollToTop from "../../components/reusable/ScrollToTop.vue";
import { routeToWebRegister } from "../../helpers/helper";
import { mapActions } from "vuex";

export default {
  components: {
    FutureSection,
    NewFooter,
    ScrollToTop,
  },
  methods: {
    ...mapActions(["scrollTop"]),
    goToRegister() {
      routeToWebRegister(this.$router);
    },
  },
  mounted() {
    this.scrollTop();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
